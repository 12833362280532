
.table-drag-select td {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0.5px solid rgb(235, 235, 235);
    line-height: 0rem;
    height:38px !important;
    font-size: 0px;
  }
  
  .table-drag-select td.cell-enabled {
    background-color: #fff;
    cursor: cell;
  }
  
  .table-drag-select td.cell-selected {
    background-color: #85fd85;
  }
  
  .table-drag-select td.cell-being-selected {
    background-color: #EACA96;
  }
  .disabledtd{
    background-color: #dedede !important;
  }
  .disabledtd1{
    background-color: #CECAAE !important;
  }



  .bookinginfotext{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:200px;
  }

.bookingInfodiv{
  z-index:100;
  position:absolute;    
  width:400px;
  background:#ebebeb;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius:8px;
}

table{
  border-spacing: 0px;
}

@media  screen and (min-width: 1866px) {
    

  .tablerowz td{
    border:1px solid #cccccc;
    height:66px;
    min-width:25px !important;
    cursor: pointer;
    background-color: #fff;
  }


  .backward{
    width: 33px;
    height:33px;
    margin-left:15px;
    margin-top:3px;
  }

  

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:52px !important;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:66px;
    min-width:88px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:85px;
  }


  .timeslot-maindivb{
    margin-top:0px;
    background-color:'white';
  }

  .roomtablecol{
    height:100%;
  }

  .headertimeline td{
    width:100px !important;
    height:25px;
    font-weight: 500;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    align-self: center;
    text-align: center;
    background-color: #fff;
  }

  .headertimeline td span{
    font-weight: 500;
    font-size: 14px;
  }

  

  
  .dragselecttimeline{
    width:25px;
  }

  .dragselecttimeline1{
    width:50px;
  }

  
}

@media only screen and (min-width : 1258px) and (max-width : 1865px) {
  

  .tablerowz td{
    border:1px solid #cccccc;
    height:55px;
    min-width:17px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .backward{
    width: 33px;
    height:33px;
    margin-left:5px;
    margin-top:3px;
  }


  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:36px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:55px;
    min-width:68px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:65px;
  }

  .timeslot-maindivb{
    margin-top:0px;
    background-color:'white';
  }

  .roomtablecol{
    height:100%;
    margin-left:0px;
  }

  .headertimeline td{
    width:72px !important;
    height:25px;
    font-weight: 500;
    font-size: 12px;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    align-self: center;
    text-align: center;
    background-color: #fff;
  }

  

  .headertimeline td span{
    font-weight: 500;
    font-size: 9px;
  }
  
  .dragselecttimeline{
    width:18px;
  }
  .dragselecttimeline1{
    width:36px;
  }
}

@media only screen and (min-width : 920px) and (max-width : 1257px) {
  
  .tablerowz td{
    border:1px solid #cccccc;
    height:55px;
    min-width:13px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .backward{
    width: 33px;
    height:33px;
    margin-left:0px;
    margin-top:3px;
  }

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:28px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:55px;
    min-width:68px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:65px;
  }


  .timeslot-maindivb{
    margin-top:0px;
    background-color:'white';
  }

  .roomtablecol{
    height:100%;
    margin-left:0px;
  }

  .headertimeline td{
    max-width:56px !important;
    height:25px;
    font-weight: 500;
    font-size: 12px;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    align-self: center;
    text-align: center;
    background-color: #fff;
  }

  


  .headertimeline td span{
    font-weight: 500;
    font-size: 12px;
  }
  
  .dragselecttimeline{
    width:14px;
  }
  .dragselecttimeline1{
    width:28px;
  }
}

/* (1920x1080) Full HD Display */
