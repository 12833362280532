@import '~antd/dist/antd.css';



.bookingsuccessmodel{
  width:600px !important;
}
.bookingsuccessmodel1{
  width:640px !important;
}

.login-form {
  width: 300px;
}
 .login-form-forgot {
  float: right;
}
.login-form-button {
  width: 30%;
  
}
.tablelongtext{
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
  width:300px;
  margin:0px;
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
  color: black;
}
.spanclass{
    font-size:18px;
    color:#ff234c;
    font-weight:bolder;
    margin-top:70px;
}

.spanclass1{
    font-size:18px;
    color:rgb(90, 90, 90);
    font-weight:bolder;
    margin-top:70px;
}

.arrow-red {
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;     
    border-right:7px solid red; 
}

.arrow-green {
  width: 0; 
  height: 0; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;     
  border-right:7px solid #59C146; 
}


.ant-tabs-nav .ant-tabs-tab:hover {
    color: #ff234c;
    
}


.ant-tabs-nav .ant-tabs-tab-active {
    color: #ff234c;
    font-weight: 500;
    background-color: white;
}

.ant-tabs-ink-bar{
    background-color: #ff234c;
}

.ant-fullcalendar-selected-day .ant-fullcalendar-value, .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value{
  background-color: #ff234c;
  color: #fff;
  box-shadow: 0 0 0 1px #ff234c inset;
}

.ant-fullcalendar-today .ant-fullcalendar-value, .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value{
  box-shadow: 0 0 0 1px #ff234c inset;
}


.PageTabs{
  padding: 10;
}
.ant-radio-button-wrapper-checked:active {
  color: #fff;
  border-color: #ff234c;
  box-shadow: -1px 0 0 0 #ff234c;
}
.ant-radio-button-wrapper:active{
  background-color: #ff234c;

}
.ant-radio-button-wrapper-checked:hover{
  color: #fff;
}
.ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #fff;
  background-color: #ff234c;
  border-color: #ff234c;
  box-shadow: -1px 0 0 0 #ff234c;
  border-color: #ff234c;
}
.ant-radio-button-wrapper .ant-radio-button-wrapper-checked{
  color: #fff;
}
.ant-radio-button-wrapper-checked:first-child {
  border-color: #ff234c;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:hover {
  border-color: #ff234c;
  box-shadow: -1px 0 0 0 #ff234c;
  color: #fff;

}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #ff234c;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color:#ff234c;
  border-color: #ff234c;
}

.ant-checkbox-disabled .ant-checkbox-inner{
  border-color: #d9d9d9 !important;
  background-color: #f5f5f5;
}

.ant-checkbox-disabled + span{
  color:#405069;
}

.ant-input-md {
  height: 40px;
  padding: 1px 7px;
}

.ant-input-sm {
  height: 32px;
  padding: 1px 7px;
}

.shortenextendtime{
  border:1px solid #455773;
  height:25px;
  color:#455773;
  text-align:center;
  margin-left:5px;
  margin-top:5px;
  cursor:pointer;
  background: white;
}

.shortenextendtimeselected{
  border:1px solid #455773;
  height:25px;
  color:#fff;
  text-align:center;
  margin-left:5px;
  margin-top:5px;
  cursor:pointer;
  background: #455773;
}


.App {
  text-align: center;
  background-color: #ececec;
  flex: 1;
  height: 100%;
  padding-bottom: 1%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.bellIcon{
width: 16px;
height: 18px;
margin-top: -5px;
}
.eyeIcon{
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-right: 15px;
}
.UserName{
  margin-left: 31px;
  margin-right: 22px;
color: #405069;
font-size: 14px;
font-weight: 500;

}
.AddonIcon{
  width: 14px;
  height: 14px;
}
.InputSizeOfThird{
  width: 155px;
}

.placeholder{
color: #405069;
font-size: 16px;
font-weight: 500;
line-height: 35.67px;
letter-spacing: 0.03px;
font-size: 15px;
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #ffffff;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #ffffff;
  -webkit-transition: all 0.3s, border 0s;
  transition: all 0.3s, border 0s;
}

.FirstDiv{
  background-color: #fff;
  border-radius: 5px;
  padding: 13px;
}
.TabsIcon{
width: 20px;
height: 14px;
margin-right: 5px;
margin-top: -4px; 
}
.IconSc{
  width: 20px;
height: 18px;
  margin-right: 5px;
  margin-top: -4px; 
  }
  .TabsBooking{
    width: 20px;
    height: 18px;
    margin-right: 5px;
    margin-top: -4px; 
    }
    .TabsData{
      width: 20px;
      height: 17px;
      margin-right: 5px;
      margin-top: -4px; 
      }
      .TabsSetting{
        width: 20px;
        height: 19px;
        margin-right: 5px;
        margin-top: -4px; 
        }   
.IconCar{
  width: 20px;
height: 16px;
margin-right: 5px;
margin-top: -4px;
}
.TabName{
  font-size: 17px;
  font-weight: 500;
}
.SignOutBtn{
  border: '1px solid #000000';
  border-width: 1px;
  border-radius: 3px;
  font-size: 11px;
  cursor: pointer;
}
.SignOutDiv{
float: right;
margin-right: 15px;
}

.logoDiv{
  text-align: left;

}

.ant-table-thead>tr>th.ant-table-column-sort {
  background: #273955;
}

.logo{
width: 150px;
height: 22px;
margin-left: 17px;
display: 'flex';
  flex: 1;
   justify-Content: 'center';
    align-Items: 'center';
}
.titleText{
  /* Style for "總部設施預約系統" */
color: #000000;
font-size: 22px;
font-weight: 600;
line-height: 38.67px;
/* Text style for "總部設施預約系統" */
letter-spacing: 0.05px;
}
.SignOutBtn{
  border:1px solid #7d8899;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #405069;
font-size: 16px;
font-weight: 500;
line-height: 38.67px;
}
.floorText{

color: #405069;
font-size: 18px;
font-weight: 500;
letter-spacing: 0.03px;
margin-left: 45px;
margin-right: 20px;
}
.floorText2{

  color: #405069;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.03px;
  margin-right: 20px;
  }
.FloorNumber{

color: #405069;
font-size: 22px;
font-weight: 500;
line-height: 38.67px;
text-transform: uppercase;
letter-spacing: 0.05px;
}
.BtnBookmark{
color: #ffffff;
font-size: 18px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.03px;
background-color: #ff7f27;
padding-top: 3px;
padding-bottom: 5px;
padding-right: 10px;
padding-left: 10px;
border-radius: 3px;
/* margin-left: 1%; */
margin-top: -6px;
margin-left: 45px;
cursor: pointer;
}

.Btnsubscribe{
  cursor: pointer;
}
.DateTextErr{

  color: #ff1b03;
  font-size: 14px;
  font-weight: 500;
}

.DateText{

color: #405069;
font-size: 14px;
font-weight: 500;
}
.NumberTextinBox{
color: #405069;
font-size: 20px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.05px;
margin-left: 9px;
margin-top: -4px;
vertical-align: middle;
}
.TextinBoxYellow{
  vertical-align: middle;
color: #ff1b03;
font-size: 15px;
font-weight: 500;
line-height: 38.67px;
float: right;
margin-right: 10px;
}
.ReportBtn{
  width: 100%;
  background-color: #455773;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-top: 33px;
}
.ReportBtnText{
  text-align: center;
  color: white;
  justify-content: center;
  cursor: pointer;
}
.minusLogo2{
  height: 17px;
  width: 17px;
  vertical-align: middle;
  margin-left: 9px;
}

.minusLogo3{
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.lagendinfo{
  font-size: 12px;
  font-weight: normal;
}

.LongText{
  color: #ff7f27;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}
.react-calendar{
  width: 250px;
  max-width: 80%;
  border: 1px solid #fff
}

.ant-btn {
  font-size: 14px;
  font-weight: 500;
  color: #455773;
  border-radius: 3px;
  background-color: #fff;
  border-color: #455773;
}

.ant-btn:hover, .ant-btn:focus {
  color: #455773;
  background-color: #fff;
  border-color: #455773;
}

.notificationp{
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
  width:370px;
}

.ant-btn-primary {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #455773;
  border-color: #455773;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-tag-checkable{
  border:1px solid #405069;
}

.ant-tag-checkable-checked{
  background-color: #405069;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover{
  background: #455773dd;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #455773dd;
}


.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background-color: #455773;
  border-color: #455773;
}
.ant-input-lg{
  font-size: 14px;
}
.ant-btn-lg {
  height:30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 14px;
}

.ant-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.65);
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
  display: none;
}

.subscribe1{
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: #455773;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 4px;
  border-color: #455773;
  margin-top: -6px;
  margin-left: 45px;
}

.subscribe{
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: #ff7f27;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 4px;
  border-color: #ff7f27;
  margin-left: 15px;
}

.subscribe:hover, .subscribe:focus {
  color: #fff;
  background-color: #ff7f27;
  border-color: #ff7f27;
}

.subscribe1:hover, .subscribe1:focus {
  color: #fff;
  background-color: #455773;
  border-color: #455773;
}

.SearchBtn{
  background-color: #455773;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 4px;
  margin-right: 17px;
  cursor: pointer;
}
.BackBtn{
  border: thin solid #677c9c;
  color: #455773;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
}
.LongText2{
  color: #ff7f27;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02px;
  text-align: center;
  margin-left: 15px;
 
}

.Projector{

color: #ffffff;
font-size: 12px;
font-weight: 500;
line-height: 30.93px;
letter-spacing: 0.03px;
background-color: #7e8da5;
padding-left: 5px;
padding-right: 5px;
margin-top: 3px;
margin-bottom: 3px;
border-radius: 2px;
margin-right: 5px;
}
.tv{
  color: #fff;
  font-size: 12px;
font-weight: 500;
line-height: 30.93px;
letter-spacing: 0.03px;
background-color: #7e8da5;
padding-left: 5px;
padding-right: 5px;
margin-top: 31px;
margin-bottom: 3px;
border-radius: 2px;
margin-right: 5px;
}
.calender{
  padding: 10%;
}
.ant-table-thead>tr>th {
  background: #455773;
  transition: background .3s ease;
  text-align: left;
  color: white;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: #ff234c;
  background: #ff234c;
  color: #fff;
 
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #ff234c;
  border-color: #ff234c;
  color: #fff;
}

.ant-radio-group {
  font-size: 14px;
  font-weight:500;
}

.minusLogo{
width: 17px;
height: 17px;
margin-right: 17px;
margin-left: 10px;
vertical-align: baseline;
}
.RoomName{
color: #405069;
font-size: 26px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.05px;
margin-top: 10px;
}

.App-header {
  background-color: #fff
}


.App-link {
  color: #61dafb;
}

.RoomInformationDiv{
  width: 97%;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 16px;
  text-align: left;
  padding: 5px;
  padding-bottom: 20px;
}

.SecondBox{
  width: 97%;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 22px;
  text-align: left;
  padding: 15px;
  margin-top: 12px;
  margin-left: 16px;
}

.TitleOfTheBox{
color: #405069;
font-size: 24px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.04px;

}

.TitleOfTheBox1{
  color: #405069;
  font-size: 14px;
  font-weight: 500;
}

.BottomTable{
  padding: 20px;
  background-color: white;
  width: 100%;
}
.CountText{
color: #405069;
font-size: 13px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.03px;
}

.customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.Iconsort{
width: 8px;
height: 11px;
float: right;
margin-top: 5px;
}
.logoTemp{
width: 10px;
height: 19px;
margin-right: 9px;
}
.TempTextPer{
  color: #405069;
font-size: 17px;
font-weight: 500;
vertical-align: middle;
margin-left: 9px;
}
.logoDrop{
width: 12px;
height: 16px;
}
.TempText{
color: #405069;
font-size: 17px;
font-weight: 500;
vertical-align: middle;
}

.customers td, .customers th {
  padding: 8px;
}
.RowTable{
  background-color: #f3f8ff;
}
.customers{
  border-radius: 5px;
  margin-top: 15px;
}
.customers tr{background-color: #f3f8ff;}



.customers th {
  padding: 10px;
  text-align: left;
  background-color: #7e8da5;
  color: white;
}
.customers td {
  padding: 7px;
  color: #405069;
font-size: 13px;
font-weight: 500;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe';
}





.FirstBoxTimeRange{
  background-color: #e4e4e4;
  padding:3px;
  border-right: 0.5px solid white;
  flex: 1;
  justify-Content: center;
  align-Items: center;
  text-align: center
}

.boxSetting{
  width: 96%;
    background-color: white;
    border-radius: 6px;
    text-align: left;
    padding: 15px;
    margin-left: 2%;
    height: 190px;
    margin-top: 5px;  
    /* margin-right: 5%; */
}
.CellSize {
 text-align: center;
 padding: 2px 6px 2px 6px;
}


.ant-table-pagination.ant-pagination{
  margin-right: 1%
}
.ant-table-tbody > tr > td{
  background-color: #f3f8ff;
color: #405069;
font-weight: 500;
}

.CountOfData{
  margin-Top:-52px;
  position: relative;
  margin-left: 17px;
  width:150px;
}


.ant-popover-buttons > .ant-btn-primary,  .ant-popover-buttons > .ant-btn{
  
  font-size: 14px;
  font-weight: 200;
}

.dashboard-box{
  width:150px; 
  height:150px; 
  background:#353535;
  margin-top:10px;
}

.dashboard-washroom-box{
  width:120px;
  height:150px;
  background:#000;
  border:1px solid white;
  border-radius:5px; 
  text-align:center;
  margin-top:10px;
}

.ant-input-number-lg{
  font-size: 14px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff; 
}

.localechangelabelselected{
  color:#ff234c;
  text-decoration: underline;
}

.localechangelabel{
  color:#ffffff;
}

.table-drag-select{
  width:auto;
  margin-left: 0px;
  margin-right: 0px;
}

@media  screen and (min-width: 1866px) {
  
  .temptextdiv{
    padding:18px;
  }

  .humitextdiv{
    float: right;
  }

  .dashboardfont{
    font-size: 26px;
  }

  .dashboardfont1{
    font-size: 40px;
  }

  .dashboard-count-box{
    width:230px;
    height:130px;
    background:#ddd;
    border-radius:8px;
    text-align:center;
  }

  .bookingDatebox{
    width:300px;
  }
  .bookingDatebox1{
    width:350px;
  }
  .bookingDatebox2{
    width:380px;
  }
  .setupinputbox{
    width:300px;
  }

  .Tab1Div{
    padding-left: 12px;
    padding-bottom: 12px;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab4Div{
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab2Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 130px);
    overflow-y: auto;
  }

  .Tab3Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Boxes{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 20px;
    width: 250px;
  }

  .Boxes1{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 20px;
    width: 95%;
  }

  .dashboardimg{
    width:300px;
    height:190px;
    margin-left:30px;
  }

  .dashboardtime{
    width:300px;
  }

  .meetinginfo{
    font-size: 18px;
    color: #405069;
    font-weight: 500;
    letter-spacing: 0.03px;
    margin-right: 20px;
  }

  .LongText1{
    color: #ff7f27;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
  }

  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:100vh;
  }

  
  .loginTitle{
    font-size: 36px;
  }

  .loginwhitebox{
    padding: 20px;
    padding-top:40px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 14px;
  }
  
}

@media only screen and (min-width : 1258px) and (max-width : 1865px) {

  .dashboardimg{
    width:200px;
    height:160px;
    margin-left:30px;
  }
  .dashboardtime{
    width:200px;
  }

  .dashboardfont{
    font-size: 20px;
  }

  .dashboardfont1{
    font-size: 20px;
  }

  .temptextdiv{
    padding:12px;
  }

  .humitextdiv{
   float: right;
  }

  .bookingDatebox{
    width:200px;
  }
  .setupinputbox{
    width:200px;
  }
  .bookingDatebox1{
    width:300px;
  }
  .bookingDatebox2{
    width:380px;
  }
  .dashboard-count-box{
    width:170px;
    height:100px;
    background:#ddd;
    border-radius:6px;
    text-align:center;
  }

  .Tab1Div{
    padding-left: 12px;
    padding-bottom: 12px;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab4Div{
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab2Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 130px);
    overflow-y: auto;
  }

  .Tab3Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Boxes{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 10px;
    width: 200px;
  }

  .Boxes1{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 10px;
    width: 95%;
  }
  
  .meetinginfo{
    font-size: 15px;
    color: #405069;
    font-weight: 500;
    letter-spacing: 0.03px;
    margin-right: 20px;
  }

  .LongText1{
    color: #ff7f27;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }

  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:100vh;
  }

  .loginwhitebox{
    padding: 20px;
    padding-top:40px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }
  
  .loginTitle{
    font-size: 36px;
  }
  .loginpagelinktxt{
    font-size: 14px;
  }
  
}

@media only screen and (min-width : 768px) and (max-width : 1257px) {

  .dashboardimg{
    width:150px;
    height:130px;
    margin-left:30px;
  }

  .dashboardtime{
    width:150px;
  }
  
  .dashboardfont{
    font-size: 20px;
  }

  .dashboardfont1{
    font-size: 20px;
  }

  .temptextdiv{
    padding:12px;
  }

  .humitextdiv{
    float: left;
  }

  .dashboard-count-box{
    width:130px;
    height:80px;
    background:#ddd;
    border-radius:4px;
    text-align:center;
  }

  .Tab1Div{
    padding-left: 12px;
    padding-bottom: 12px;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab4Div{
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab2Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 130px);
    overflow-y: auto;
  }

  .Tab3Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:35px;
    text-align: left;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .bookingDatebox{
    width:150px;
  }

  .bookingDatebox1{
    width:250px;
  }
  .bookingDatebox2{
    width:380px;
  }
  .setupinputbox{
    width:150px;
  }

  .bookingcalanderdiv{
    width: 80%;
    margin-left: 10%;
  }

  .bookinginfodiv{
    margin-left: 70px;
  }

  .bookinginfodiv1{
    margin-left: 10%;
    width: 80%;
    margin-top:30px;
  }

  .Boxes{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 5px;
    width: 170px;
  }

  .Boxes1{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 5px;
    width: 95%;
  }

  .meetinginfo{
    font-size: 12px;
    color: #405069;
    font-weight: 500;
    letter-spacing: 0.03px;
    margin-right: 20px;
  }

  .LongText1{
    color: #ff7f27;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:100vh;
  }

  .loginTitle{
    font-size: 36px;
  }
  .loginwhitebox{
    padding: 20px;
    padding-top:40px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }
  .loginpagelinktxt{
    font-size: 14px;
  }
  
}

@media only screen and (min-width : 501px) and (max-width : 767px) {
  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:110vh;
  }

  .loginTitle{
    font-size: 25px;
  }

  .loginwhitebox{
    padding: 10px;
    padding-top:20px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 14px;
  }
 
}

@media only screen and (min-width : 361px) and (max-width : 500px) {
  .loginform{
    width:250px;
  }

  .loginpageimagediv{
    height:110vh;
  }

  .loginTitle{
    font-size: 20px;
  }

  .loginwhitebox{
    padding: 10px;
    padding-top:20px;
    background: white;
    border-radius: 6px;
    width:340px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 12px;
  }
 
}

@media only screen and (min-width : 100px) and (max-width : 360px) {
  .loginform{
    width:250px;
  }

  .loginpageimagediv{
    height:110vh;
  }

  .loginTitle{
    font-size: 16px;
    padding:10px;
  }

  .loginwhitebox{
    padding: 10px;
    padding-top:20px;
    background: white;
    border-radius: 6px;
    width:300px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 12px;
  }

}


