
html {
  scrollbar-color: #787881 #e0e0e0;
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rootdivclass{
  width: 100%; height:100%; overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.bookingsuccessmodel{
  width:600px !important;
}
.bookingsuccessmodel1{
  width:640px !important;
}

.login-form {
  width: 300px;
}
 .login-form-forgot {
  float: right;
}
.login-form-button {
  width: 30%;
  
}
.tablelongtext{
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
  width:300px;
  margin:0px;
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
  color: black;
}
.spanclass{
    font-size:18px;
    color:#ff234c;
    font-weight:bolder;
    margin-top:70px;
}

.spanclass1{
    font-size:18px;
    color:rgb(90, 90, 90);
    font-weight:bolder;
    margin-top:70px;
}

.arrow-red {
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;     
    border-right:7px solid red; 
}

.arrow-green {
  width: 0; 
  height: 0; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;     
  border-right:7px solid #59C146; 
}


.ant-tabs-nav .ant-tabs-tab:hover {
    color: #ff234c;
    
}


.ant-tabs-nav .ant-tabs-tab-active {
    color: #ff234c;
    font-weight: 500;
    background-color: white;
}

.ant-tabs-ink-bar{
    background-color: #ff234c;
}

.ant-fullcalendar-selected-day .ant-fullcalendar-value, .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value{
  background-color: #ff234c;
  color: #fff;
  box-shadow: 0 0 0 1px #ff234c inset;
}

.ant-fullcalendar-today .ant-fullcalendar-value, .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value{
  box-shadow: 0 0 0 1px #ff234c inset;
}


.PageTabs{
  padding: 10;
}
.ant-radio-button-wrapper-checked:active {
  color: #fff;
  border-color: #ff234c;
  box-shadow: -1px 0 0 0 #ff234c;
}
.ant-radio-button-wrapper:active{
  background-color: #ff234c;

}
.ant-radio-button-wrapper-checked:hover{
  color: #fff;
}
.ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #fff;
  background-color: #ff234c;
  border-color: #ff234c;
  box-shadow: -1px 0 0 0 #ff234c;
  border-color: #ff234c;
}
.ant-radio-button-wrapper .ant-radio-button-wrapper-checked{
  color: #fff;
}
.ant-radio-button-wrapper-checked:first-child {
  border-color: #ff234c;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:hover {
  border-color: #ff234c;
  box-shadow: -1px 0 0 0 #ff234c;
  color: #fff;

}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #ff234c;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color:#ff234c;
  border-color: #ff234c;
}

.ant-checkbox-disabled .ant-checkbox-inner{
  border-color: #d9d9d9 !important;
  background-color: #f5f5f5;
}

.ant-checkbox-disabled + span{
  color:#405069;
}

.ant-input-md {
  height: 40px;
  padding: 1px 7px;
}

.ant-input-sm {
  height: 32px;
  padding: 1px 7px;
}

.shortenextendtime{
  border:1px solid #455773;
  height:25px;
  color:#455773;
  text-align:center;
  margin-left:5px;
  margin-top:5px;
  cursor:pointer;
  background: white;
}

.shortenextendtimeselected{
  border:1px solid #455773;
  height:25px;
  color:#fff;
  text-align:center;
  margin-left:5px;
  margin-top:5px;
  cursor:pointer;
  background: #455773;
}


.App {
  text-align: center;
  background-color: #ececec;
  -ms-flex: 1 1;
      flex: 1 1;
  height: 100%;
  padding-bottom: 1%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.bellIcon{
width: 16px;
height: 18px;
margin-top: -5px;
}
.eyeIcon{
    width: 25px;
    height: 25px;
    margin-top: -3px;
    margin-right: 15px;
}
.UserName{
  margin-left: 31px;
  margin-right: 22px;
color: #405069;
font-size: 14px;
font-weight: 500;

}
.AddonIcon{
  width: 14px;
  height: 14px;
}
.InputSizeOfThird{
  width: 155px;
}

.placeholder{
color: #405069;
font-size: 16px;
font-weight: 500;
line-height: 35.67px;
letter-spacing: 0.03px;
font-size: 15px;
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #ffffff;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #ffffff;
  transition: all 0.3s, border 0s;
}

.FirstDiv{
  background-color: #fff;
  border-radius: 5px;
  padding: 13px;
}
.TabsIcon{
width: 20px;
height: 14px;
margin-right: 5px;
margin-top: -4px; 
}
.IconSc{
  width: 20px;
height: 18px;
  margin-right: 5px;
  margin-top: -4px; 
  }
  .TabsBooking{
    width: 20px;
    height: 18px;
    margin-right: 5px;
    margin-top: -4px; 
    }
    .TabsData{
      width: 20px;
      height: 17px;
      margin-right: 5px;
      margin-top: -4px; 
      }
      .TabsSetting{
        width: 20px;
        height: 19px;
        margin-right: 5px;
        margin-top: -4px; 
        }   
.IconCar{
  width: 20px;
height: 16px;
margin-right: 5px;
margin-top: -4px;
}
.TabName{
  font-size: 17px;
  font-weight: 500;
}
.SignOutBtn{
  border: '1px solid #000000';
  border-width: 1px;
  border-radius: 3px;
  font-size: 11px;
  cursor: pointer;
}
.SignOutDiv{
float: right;
margin-right: 15px;
}

.logoDiv{
  text-align: left;

}

.ant-table-thead>tr>th.ant-table-column-sort {
  background: #273955;
}

.logo{
width: 150px;
height: 22px;
margin-left: 17px;
display: 'flex';
  -ms-flex: 1 1;
      flex: 1 1;
   justify-Content: 'center';
    align-Items: 'center';
}
.titleText{
  /* Style for "總部設施預約系統" */
color: #000000;
font-size: 22px;
font-weight: 600;
line-height: 38.67px;
/* Text style for "總部設施預約系統" */
letter-spacing: 0.05px;
}
.SignOutBtn{
  border:1px solid #7d8899;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #405069;
font-size: 16px;
font-weight: 500;
line-height: 38.67px;
}
.floorText{

color: #405069;
font-size: 18px;
font-weight: 500;
letter-spacing: 0.03px;
margin-left: 45px;
margin-right: 20px;
}
.floorText2{

  color: #405069;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.03px;
  margin-right: 20px;
  }
.FloorNumber{

color: #405069;
font-size: 22px;
font-weight: 500;
line-height: 38.67px;
text-transform: uppercase;
letter-spacing: 0.05px;
}
.BtnBookmark{
color: #ffffff;
font-size: 18px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.03px;
background-color: #ff7f27;
padding-top: 3px;
padding-bottom: 5px;
padding-right: 10px;
padding-left: 10px;
border-radius: 3px;
/* margin-left: 1%; */
margin-top: -6px;
margin-left: 45px;
cursor: pointer;
}

.Btnsubscribe{
  cursor: pointer;
}
.DateTextErr{

  color: #ff1b03;
  font-size: 14px;
  font-weight: 500;
}

.DateText{

color: #405069;
font-size: 14px;
font-weight: 500;
}
.NumberTextinBox{
color: #405069;
font-size: 20px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.05px;
margin-left: 9px;
margin-top: -4px;
vertical-align: middle;
}
.TextinBoxYellow{
  vertical-align: middle;
color: #ff1b03;
font-size: 15px;
font-weight: 500;
line-height: 38.67px;
float: right;
margin-right: 10px;
}
.ReportBtn{
  width: 100%;
  background-color: #455773;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-top: 33px;
}
.ReportBtnText{
  text-align: center;
  color: white;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer;
}
.minusLogo2{
  height: 17px;
  width: 17px;
  vertical-align: middle;
  margin-left: 9px;
}

.minusLogo3{
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.lagendinfo{
  font-size: 12px;
  font-weight: normal;
}

.LongText{
  color: #ff7f27;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}
.react-calendar{
  width: 250px;
  max-width: 80%;
  border: 1px solid #fff
}

.ant-btn {
  font-size: 14px;
  font-weight: 500;
  color: #455773;
  border-radius: 3px;
  background-color: #fff;
  border-color: #455773;
}

.ant-btn:hover, .ant-btn:focus {
  color: #455773;
  background-color: #fff;
  border-color: #455773;
}

.notificationp{
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
  width:370px;
}

.ant-btn-primary {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #455773;
  border-color: #455773;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-tag-checkable{
  border:1px solid #405069;
}

.ant-tag-checkable-checked{
  background-color: #405069;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover{
  background: #455773dd;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #455773dd;
}


.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background-color: #455773;
  border-color: #455773;
}
.ant-input-lg{
  font-size: 14px;
}
.ant-btn-lg {
  height:30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 14px;
}

.ant-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.65);
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
  display: none;
}

.subscribe1{
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: #455773;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 4px;
  border-color: #455773;
  margin-top: -6px;
  margin-left: 45px;
}

.subscribe{
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: #ff7f27;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 4px;
  border-color: #ff7f27;
  margin-left: 15px;
}

.subscribe:hover, .subscribe:focus {
  color: #fff;
  background-color: #ff7f27;
  border-color: #ff7f27;
}

.subscribe1:hover, .subscribe1:focus {
  color: #fff;
  background-color: #455773;
  border-color: #455773;
}

.SearchBtn{
  background-color: #455773;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 4px;
  margin-right: 17px;
  cursor: pointer;
}
.BackBtn{
  border: thin solid #677c9c;
  color: #455773;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
}
.LongText2{
  color: #ff7f27;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02px;
  text-align: center;
  margin-left: 15px;
 
}

.Projector{

color: #ffffff;
font-size: 12px;
font-weight: 500;
line-height: 30.93px;
letter-spacing: 0.03px;
background-color: #7e8da5;
padding-left: 5px;
padding-right: 5px;
margin-top: 3px;
margin-bottom: 3px;
border-radius: 2px;
margin-right: 5px;
}
.tv{
  color: #fff;
  font-size: 12px;
font-weight: 500;
line-height: 30.93px;
letter-spacing: 0.03px;
background-color: #7e8da5;
padding-left: 5px;
padding-right: 5px;
margin-top: 31px;
margin-bottom: 3px;
border-radius: 2px;
margin-right: 5px;
}
.calender{
  padding: 10%;
}
.ant-table-thead>tr>th {
  background: #455773;
  transition: background .3s ease;
  text-align: left;
  color: white;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: #ff234c;
  background: #ff234c;
  color: #fff;
 
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #ff234c;
  border-color: #ff234c;
  color: #fff;
}

.ant-radio-group {
  font-size: 14px;
  font-weight:500;
}

.minusLogo{
width: 17px;
height: 17px;
margin-right: 17px;
margin-left: 10px;
vertical-align: baseline;
}
.RoomName{
color: #405069;
font-size: 26px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.05px;
margin-top: 10px;
}

.App-header {
  background-color: #fff
}


.App-link {
  color: #61dafb;
}

.RoomInformationDiv{
  width: 97%;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 16px;
  text-align: left;
  padding: 5px;
  padding-bottom: 20px;
}

.SecondBox{
  width: 97%;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 22px;
  text-align: left;
  padding: 15px;
  margin-top: 12px;
  margin-left: 16px;
}

.TitleOfTheBox{
color: #405069;
font-size: 24px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.04px;

}

.TitleOfTheBox1{
  color: #405069;
  font-size: 14px;
  font-weight: 500;
}

.BottomTable{
  padding: 20px;
  background-color: white;
  width: 100%;
}
.CountText{
color: #405069;
font-size: 13px;
font-weight: 500;
line-height: 38.67px;
letter-spacing: 0.03px;
}

.customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.Iconsort{
width: 8px;
height: 11px;
float: right;
margin-top: 5px;
}
.logoTemp{
width: 10px;
height: 19px;
margin-right: 9px;
}
.TempTextPer{
  color: #405069;
font-size: 17px;
font-weight: 500;
vertical-align: middle;
margin-left: 9px;
}
.logoDrop{
width: 12px;
height: 16px;
}
.TempText{
color: #405069;
font-size: 17px;
font-weight: 500;
vertical-align: middle;
}

.customers td, .customers th {
  padding: 8px;
}
.RowTable{
  background-color: #f3f8ff;
}
.customers{
  border-radius: 5px;
  margin-top: 15px;
}
.customers tr{background-color: #f3f8ff;}



.customers th {
  padding: 10px;
  text-align: left;
  background-color: #7e8da5;
  color: white;
}
.customers td {
  padding: 7px;
  color: #405069;
font-size: 13px;
font-weight: 500;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe';
}





.FirstBoxTimeRange{
  background-color: #e4e4e4;
  padding:3px;
  border-right: 0.5px solid white;
  -ms-flex: 1 1;
      flex: 1 1;
  justify-Content: center;
  align-Items: center;
  text-align: center
}

.boxSetting{
  width: 96%;
    background-color: white;
    border-radius: 6px;
    text-align: left;
    padding: 15px;
    margin-left: 2%;
    height: 190px;
    margin-top: 5px;  
    /* margin-right: 5%; */
}
.CellSize {
 text-align: center;
 padding: 2px 6px 2px 6px;
}


.ant-table-pagination.ant-pagination{
  margin-right: 1%
}
.ant-table-tbody > tr > td{
  background-color: #f3f8ff;
color: #405069;
font-weight: 500;
}

.CountOfData{
  margin-Top:-52px;
  position: relative;
  margin-left: 17px;
  width:150px;
}


.ant-popover-buttons > .ant-btn-primary,  .ant-popover-buttons > .ant-btn{
  
  font-size: 14px;
  font-weight: 200;
}

.dashboard-box{
  width:150px; 
  height:150px; 
  background:#353535;
  margin-top:10px;
}

.dashboard-washroom-box{
  width:120px;
  height:150px;
  background:#000;
  border:1px solid white;
  border-radius:5px; 
  text-align:center;
  margin-top:10px;
}

.ant-input-number-lg{
  font-size: 14px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff; 
}

.localechangelabelselected{
  color:#ff234c;
  text-decoration: underline;
}

.localechangelabel{
  color:#ffffff;
}

.table-drag-select{
  width:auto;
  margin-left: 0px;
  margin-right: 0px;
}

@media  screen and (min-width: 1866px) {
  
  .temptextdiv{
    padding:18px;
  }

  .humitextdiv{
    float: right;
  }

  .dashboardfont{
    font-size: 26px;
  }

  .dashboardfont1{
    font-size: 40px;
  }

  .dashboard-count-box{
    width:230px;
    height:130px;
    background:#ddd;
    border-radius:8px;
    text-align:center;
  }

  .bookingDatebox{
    width:300px;
  }
  .bookingDatebox1{
    width:350px;
  }
  .bookingDatebox2{
    width:380px;
  }
  .setupinputbox{
    width:300px;
  }

  .Tab1Div{
    padding-left: 12px;
    padding-bottom: 12px;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab4Div{
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab2Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 130px);
    overflow-y: auto;
  }

  .Tab3Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Boxes{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 20px;
    width: 250px;
  }

  .Boxes1{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 20px;
    width: 95%;
  }

  .dashboardimg{
    width:300px;
    height:190px;
    margin-left:30px;
  }

  .dashboardtime{
    width:300px;
  }

  .meetinginfo{
    font-size: 18px;
    color: #405069;
    font-weight: 500;
    letter-spacing: 0.03px;
    margin-right: 20px;
  }

  .LongText1{
    color: #ff7f27;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
  }

  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:100vh;
  }

  
  .loginTitle{
    font-size: 36px;
  }

  .loginwhitebox{
    padding: 20px;
    padding-top:40px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 14px;
  }
  
}

@media only screen and (min-width : 1258px) and (max-width : 1865px) {

  .dashboardimg{
    width:200px;
    height:160px;
    margin-left:30px;
  }
  .dashboardtime{
    width:200px;
  }

  .dashboardfont{
    font-size: 20px;
  }

  .dashboardfont1{
    font-size: 20px;
  }

  .temptextdiv{
    padding:12px;
  }

  .humitextdiv{
   float: right;
  }

  .bookingDatebox{
    width:200px;
  }
  .setupinputbox{
    width:200px;
  }
  .bookingDatebox1{
    width:300px;
  }
  .bookingDatebox2{
    width:380px;
  }
  .dashboard-count-box{
    width:170px;
    height:100px;
    background:#ddd;
    border-radius:6px;
    text-align:center;
  }

  .Tab1Div{
    padding-left: 12px;
    padding-bottom: 12px;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab4Div{
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab2Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 130px);
    overflow-y: auto;
  }

  .Tab3Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Boxes{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 10px;
    width: 200px;
  }

  .Boxes1{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 10px;
    width: 95%;
  }
  
  .meetinginfo{
    font-size: 15px;
    color: #405069;
    font-weight: 500;
    letter-spacing: 0.03px;
    margin-right: 20px;
  }

  .LongText1{
    color: #ff7f27;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }

  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:100vh;
  }

  .loginwhitebox{
    padding: 20px;
    padding-top:40px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }
  
  .loginTitle{
    font-size: 36px;
  }
  .loginpagelinktxt{
    font-size: 14px;
  }
  
}

@media only screen and (min-width : 768px) and (max-width : 1257px) {

  .dashboardimg{
    width:150px;
    height:130px;
    margin-left:30px;
  }

  .dashboardtime{
    width:150px;
  }
  
  .dashboardfont{
    font-size: 20px;
  }

  .dashboardfont1{
    font-size: 20px;
  }

  .temptextdiv{
    padding:12px;
  }

  .humitextdiv{
    float: left;
  }

  .dashboard-count-box{
    width:130px;
    height:80px;
    background:#ddd;
    border-radius:4px;
    text-align:center;
  }

  .Tab1Div{
    padding-left: 12px;
    padding-bottom: 12px;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab4Div{
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .Tab2Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    text-align: left;
    height:calc(100vh - 130px);
    overflow-y: auto;
  }

  .Tab3Div{
    padding-left:15px;
    padding-right:15px;
    padding-bottom:35px;
    text-align: left;
    height:calc(100vh - 120px);
    overflow-y: auto;
  }

  .bookingDatebox{
    width:150px;
  }

  .bookingDatebox1{
    width:250px;
  }
  .bookingDatebox2{
    width:380px;
  }
  .setupinputbox{
    width:150px;
  }

  .bookingcalanderdiv{
    width: 80%;
    margin-left: 10%;
  }

  .bookinginfodiv{
    margin-left: 70px;
  }

  .bookinginfodiv1{
    margin-left: 10%;
    width: 80%;
    margin-top:30px;
  }

  .Boxes{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 5px;
    width: 170px;
  }

  .Boxes1{
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin-right: 5px;
    width: 95%;
  }

  .meetinginfo{
    font-size: 12px;
    color: #405069;
    font-weight: 500;
    letter-spacing: 0.03px;
    margin-right: 20px;
  }

  .LongText1{
    color: #ff7f27;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:100vh;
  }

  .loginTitle{
    font-size: 36px;
  }
  .loginwhitebox{
    padding: 20px;
    padding-top:40px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }
  .loginpagelinktxt{
    font-size: 14px;
  }
  
}

@media only screen and (min-width : 501px) and (max-width : 767px) {
  .loginform{
    width:300px;
  }

  .loginpageimagediv{
    height:110vh;
  }

  .loginTitle{
    font-size: 25px;
  }

  .loginwhitebox{
    padding: 10px;
    padding-top:20px;
    background: white;
    border-radius: 6px;
    width:500px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 14px;
  }
 
}

@media only screen and (min-width : 361px) and (max-width : 500px) {
  .loginform{
    width:250px;
  }

  .loginpageimagediv{
    height:110vh;
  }

  .loginTitle{
    font-size: 20px;
  }

  .loginwhitebox{
    padding: 10px;
    padding-top:20px;
    background: white;
    border-radius: 6px;
    width:340px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 12px;
  }
 
}

@media only screen and (min-width : 100px) and (max-width : 360px) {
  .loginform{
    width:250px;
  }

  .loginpageimagediv{
    height:110vh;
  }

  .loginTitle{
    font-size: 16px;
    padding:10px;
  }

  .loginwhitebox{
    padding: 10px;
    padding-top:20px;
    background: white;
    border-radius: 6px;
    width:300px;
    text-align:center;
  }

  .loginpagelinktxt{
    font-size: 12px;
  }

}




.table-drag-select td {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0.5px solid rgb(235, 235, 235);
    line-height: 0rem;
    height:38px !important;
    font-size: 0px;
  }
  
  .table-drag-select td.cell-enabled {
    background-color: #fff;
    cursor: cell;
  }
  
  .table-drag-select td.cell-selected {
    background-color: #85fd85;
  }
  
  .table-drag-select td.cell-being-selected {
    background-color: #EACA96;
  }
  .disabledtd{
    background-color: #dedede !important;
  }
  .disabledtd1{
    background-color: #CECAAE !important;
  }



  .bookinginfotext{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:200px;
  }

.bookingInfodiv{
  z-index:100;
  position:absolute;    
  width:400px;
  background:#ebebeb;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius:8px;
}

table{
  border-spacing: 0px;
}

@media  screen and (min-width: 1866px) {
    

  .tablerowz td{
    border:1px solid #cccccc;
    height:66px;
    min-width:25px !important;
    cursor: pointer;
    background-color: #fff;
  }


  .backward{
    width: 33px;
    height:33px;
    margin-left:15px;
    margin-top:3px;
  }

  

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:52px !important;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:66px;
    min-width:88px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:85px;
  }


  .timeslot-maindivb{
    margin-top:0px;
    background-color:'white';
  }

  .roomtablecol{
    height:100%;
  }

  .headertimeline td{
    width:100px !important;
    height:25px;
    font-weight: 500;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    background-color: #fff;
  }

  .headertimeline td span{
    font-weight: 500;
    font-size: 14px;
  }

  

  
  .dragselecttimeline{
    width:25px;
  }

  .dragselecttimeline1{
    width:50px;
  }

  
}

@media only screen and (min-width : 1258px) and (max-width : 1865px) {
  

  .tablerowz td{
    border:1px solid #cccccc;
    height:55px;
    min-width:17px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .backward{
    width: 33px;
    height:33px;
    margin-left:5px;
    margin-top:3px;
  }


  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:36px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:55px;
    min-width:68px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:65px;
  }

  .timeslot-maindivb{
    margin-top:0px;
    background-color:'white';
  }

  .roomtablecol{
    height:100%;
    margin-left:0px;
  }

  .headertimeline td{
    width:72px !important;
    height:25px;
    font-weight: 500;
    font-size: 12px;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    background-color: #fff;
  }

  

  .headertimeline td span{
    font-weight: 500;
    font-size: 9px;
  }
  
  .dragselecttimeline{
    width:18px;
  }
  .dragselecttimeline1{
    width:36px;
  }
}

@media only screen and (min-width : 920px) and (max-width : 1257px) {
  
  .tablerowz td{
    border:1px solid #cccccc;
    height:55px;
    min-width:13px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .backward{
    width: 33px;
    height:33px;
    margin-left:0px;
    margin-top:3px;
  }

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:28px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:55px;
    min-width:68px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:65px;
  }


  .timeslot-maindivb{
    margin-top:0px;
    background-color:'white';
  }

  .roomtablecol{
    height:100%;
    margin-left:0px;
  }

  .headertimeline td{
    max-width:56px !important;
    height:25px;
    font-weight: 500;
    font-size: 12px;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    background-color: #fff;
  }

  


  .headertimeline td span{
    font-weight: 500;
    font-size: 12px;
  }
  
  .dragselecttimeline{
    width:14px;
  }
  .dragselecttimeline1{
    width:28px;
  }
}

/* (1920x1080) Full HD Display */

@media screen and (min-width: 1866px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        -ms-flex-align: center;
            align-items: center;
        font-size: 14px;
        font-weight: 500;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 210px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 180px;
    }

    .dayboxs1 {
        width: 210px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 45px;
        min-width: 210px !important;
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 210px !important;
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 90px !important;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 45px;
        min-width: 22.5px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 22.5px !important;
        cursor: pointer;
        background-color: #fff;
    }
}

@media only screen and (min-width : 1710px) and (max-width : 1875px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        -ms-flex-align: center;
            align-items: center;
        font-size: 12px;
        font-weight: 500;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 180px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 165px;
    }

    .dayboxs1 {
        width: 180px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 180px !important;
        text-align: center;
        font-size: 10px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 180px !important;
        text-align: center;
        font-size: 10px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 82.5px !important;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 20.625px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 20.625px !important;
        cursor: pointer;
        background-color: #fff;
    }
}


@media only screen and (min-width : 1500px) and (max-width : 1709px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 11px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 11px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        -ms-flex-align: center;
            align-items: center;
        font-size: 11px;
        font-weight: 500;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 170px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 140px;
    }

    .dayboxs1 {
        width: 170px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 170px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 170px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 70px !important;
        cursor: pointer;
        text-align: center;
        font-size: 11px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 17.5px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 17.5px !important;
        cursor: pointer;
        background-color: #fff;
    }
}


@media only screen and (min-width : 1260px) and (max-width : 1499px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        -ms-flex-align: center;
            align-items: center;
        font-size: 10px;
        font-weight: 450;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 160px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 115px;
    }

    .dayboxs1 {
        width: 160px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 57.5px !important;
        cursor: pointer;
        text-align: center;
        font-size: 10px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 14.375px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 14.375px !important;
        cursor: pointer;
        background-color: #fff;
    }
}

@media only screen and (min-width : 920px) and (max-width : 1259px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        -ms-flex: 1 1;
            flex: 1 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        -ms-flex-align: center;
            align-items: center;
        font-size: 10px;
        font-weight: 450;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 160px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 110px;
    }

    .dayboxs1 {
        width: 160px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 55px !important;
        cursor: pointer;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 13.75px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 13.75px !important;
        cursor: pointer;
        background-color: #fff;
    }
}

.table-drag-select td {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0.5px solid rgb(235, 235, 235);
    line-height: 0rem;
    height:38px !important;
    font-size: 0px;
  }
  
  .table-drag-select td.cell-enabled {
    background-color: #fff;
    cursor: cell;
  }
  
  .table-drag-select td.cell-selected {
    background-color: #85fd85;
  }
  
  .table-drag-select td.cell-being-selected {
    background-color: #EACA96;
  }
  .disabledtd{
    background-color: #dedede !important;
  }
  .disabledtd1{
    background-color: #CECAAE !important;
  }
  

  



  .bookinginfotext{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:200px;
  }

.bookingInfodiv{
  z-index:100;
  position:absolute;    
  width:400px;
  background:#ebebeb;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius:8px;
}

table{
  border-spacing: 0px;
}

@media  screen and (min-width: 1866px) {
  .roomnamecol{
    height:30px !important;
  }

  .tablerow td{
    border:1px solid #cccccc;
    height:45px;
    min-width:25px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .tablerowz td{
    border:1px solid #cccccc;
    height:66px;
    min-width:23px !important;
    cursor: pointer;
    background-color: #fff;
  }


  .backward{
    width: 33px;
    height:33px;
    margin-left:15px;
    margin-top:3px;
  }

  .tablerow2td{
    border:1px solid #cccccc;
    height:30px;
    width:100px !important;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
    
  }

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:52px !important;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
    
  }

  .tablerow1 td{
    border:1px solid #cccccc;
    height:45px;
    min-width:88px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
  }

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:66px;
    min-width:190px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:190px;
  }


  .timeslot-maindiv{
    margin-left:36px;
    margin-top:0px;
    background-color:'white';
    width:1321px;
  }

  .timeslot-maindivz{
    margin-left:136px;
    margin-top:0px;
    background-color:'white';
    width:1197px;
  }

  .roomtablecol{
    height:100%;
    margin-left:50px;
  }

  .headertimeline td{
    width:100px !important;
    height:25px;
    font-weight: 500;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    background-color: #fff;
  }

  .headertimeline td span{
    font-weight: 500;
    font-size: 14px;
  }

  

  
  .dragselecttimeline{
    width:25px;
  }

  .dragselecttimeline1{
    width:50px;
  }
}

@media only screen and (min-width : 1311px) and (max-width : 1865px) {
  .roomnamecol{
    height:30px !important;
  }
  .tablerow td{
    border:1px solid #cccccc;
    height:35px;
    min-width:17px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .tablerowz td{
    border:1px solid #cccccc;
    height:55px;
    min-width:16px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .backward{
    width: 33px;
    height:33px;
    margin-left:5px;
    margin-top:3px;
  }

  .tablerow2td{
    border:1px solid #cccccc;
    height:30px;
    width:68px !important;
    cursor: pointer;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
    
  }

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:36px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  .tablerow1 td{
    border:1px solid #cccccc;
    height:35px;
    min-width:68px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:55px;
    min-width:170px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:170px;
  }

  .timeslot-maindiv{
    margin-left:36px;
    margin-top:0px;
    background-color:'white';
    width:900px;
  }

  .timeslot-maindivz{
    margin-left:141px;
    margin-top:0px;
    background-color:'white';
    width:833px;
  }

  .roomtablecol{
    height:100%;
    margin-left:0px;
  }

  .headertimeline td{
    width:72px !important;
    height:25px;
    font-weight: 500;
    font-size: 12px;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    background-color: #fff;
  }

  

  .headertimeline td span{
    font-weight: 500;
    font-size: 9px;
  }
  
  .dragselecttimeline{
    width:18px;
  }
  .dragselecttimeline1{
    width:36px;
  }
}

@media only screen and (min-width : 920px) and (max-width : 1312px) {
  .roomnamecol{
    height:39px !important;
  }
  .tablerow td{
    border:1px solid #cccccc;
    height:35px;
    min-width:13px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .tablerowz td{
    border:1px solid #cccccc;
    height:55px;
    min-width:13px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .backward{
    width: 33px;
    height:33px;
    margin-left:0px;
    margin-top:3px;
  }


  .tablerow2td{
    border:1px solid #cccccc;
    height:30px;
    width:52px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  .tablerow3td{
    border:1px solid #cccccc;
    height:30px;
    min-width:28px !important;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    background-color: #fff;
    
  }

  

  .tablerow1 td{
    border:1px solid #cccccc;
    height:35px;
    min-width:68px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tablerowz1 td{
    border:1px solid #cccccc;
    height:55px;
    min-width:160px !important;
    text-align: center;
    font-size: 11px;
    font-weight: 450;
    background-color: #fff;
  }

  .tddiv{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:160px;
  }


  .timeslot-maindiv{
    margin-left:40px;
    margin-top:0px;
    background-color:'white';
    width:700px;
  }

  .timeslot-maindivz{
    margin-left:130px;
    margin-top:0px;
    background-color:'white';
    width:700px;
  }

  .roomtablecol{
    height:100%;
    margin-left:0px;
  }

  .headertimeline td{
    max-width:56px !important;
    height:25px;
    font-weight: 500;
    font-size: 12px;
    border:0.5px solid rgb(235, 235, 235);
    border-bottom: 0px;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    background-color: #fff;
  }

  


  .headertimeline td span{
    font-weight: 500;
    font-size: 12px;
  }
  
  .dragselecttimeline{
    width:14px;
  }
  .dragselecttimeline1{
    width:28px;
  }
}

/* (1920x1080) Full HD Display */

.PageTabs{
    padding: 10;
}
.ant-radio-button-wrapper-checked:active {
    color: #fff;
    border-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
}
.ant-radio-button-wrapper:active{
    background-color: #ff234c;

}
.ant-radio-button-wrapper-checked:hover{
    color: #fff;
}
.TitleOfTheBox{
    text-align:left; 
}
.ant-radio-button-wrapper-checked {
    z-index: 1;
    color: #fff;
    background-color: #ff234c;
    border-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
    border-color: #ff234c;
}
.ant-radio-button-wrapper ant-radio-button-wrapper-checked{
    color: #fff;
}
.ant-radio-button-wrapper-checked:first-child {
    border-color: #ff234c;
    box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:hover {
    border-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
    color: #fff;

}

.ant-switch-checked{
    background-color: #ff234c;
}

.MainTable{
    width: 100%;
}
.box{
    width: 100%;
    background-color: white;
    border-radius: 6px;
    height: 100px;
    text-align: left;
}

.Removiebtn{
    background-color: #455773;
    color: #fff;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px;
    margin-left: 10%;
    cursor: pointer;
    /* margin-right: 17px; */
}

.boxSetting{
    width: 96%;
      background-color: white;
      border-radius: 6px;
      text-align: left;
      padding: 15px;
      margin-left: 2%;
      height: 310px;
      margin-top: 5px;  
      /* margin-right: 5%; */
}

.TitleOfTheBox{
    color: #405069;
    font-size: 18px;
    font-weight: 500;
    line-height: 38.67px;
    letter-spacing: 0.04px;
    
}
.SmallTextRoom{
    color: #405069;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.04px;
    margin-bottom: 4px;
}

.Editbtn{
    background-color: #455773;
    color: #fff;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    margin-left: 10%;
    cursor: pointer;
    /* margin-right: 17px; */
}
.Addbtn{
    background-color: #455773;
    color: #fff;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    cursor: pointer;
    /* margin-right: 17px; */
}
.yellowTextRoom{
  color: #ff7f27;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02px;
  text-align: center;

}
.ant-select-lg{
    font-size: 14px;
}

.zoomsetting .ant-select-lg .ant-select-selection--single{
    height: 38px !important;
}

.bookingselect .ant-select-lg .ant-select-selection--single{
    height: 40px !important;
}

.zoomsetting .ant-select-lg .ant-select-selection__rendered {
    line-height: 38px !important;
}

.bookingselect .ant-select-lg .ant-select-selection__rendered {
    line-height: 40px !important;
}

/* .ant-select-lg .ant-select-selection--single {
    height: 30px;
}

.ant-select-lg .ant-select-selection__rendered {
    line-height: 28px;
} */
.ant-select-disabled .ant-select-selection{
    color:rgba(0, 0, 0, 0.65);
}

.ant-list-pagination {
    margin-top: 0px;
    text-align: right;
}
.Tab2Div1 {
    height: calc(100vh - 140px);
    overflow-y:auto;
}


.ant-tooltip-inner {
    width: 300px;
}

.summarycol1{
    border:1px solid #aaaaaa;
    border-right:0px;
    border-top:0px;
    height:20px;
    text-align:center;
}

.summarycol{
    border:1px solid #aaaaaa;
    border-right:0px;
    height:20px;
    text-align:center;
}

.sharing .ant-select-selection__rendered{
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    line-height: 24px;
}

.sharing1 .ant-select-selection__rendered{
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    line-height: 30px;
}

.sharing .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td{
    padding:4px 4px;
}

.sharing .ant-btn-danger {
    color: #ffffff;
    background-color: #f5222d;
    border-color: #f5222d;
}

.sharing .ant-table-thead, .sharing .ant-table-thead>tr>th{
    background: #002140;
    color:white;
}
  
.sharing .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover{
    background: #0380f57c;
}

.sharing .ant-table-tbody{
    background: #fff;
}

.sharing .ant-table-tbody > tr > td {
    background-color: #fff;
    color: #405069;
    font-weight: 400;
    font-size: 13px;
}

.tablerowselcted > td{
    background-color: bisque !important;
    background: bisque !important;
}


.sharing .ant-table-tbody > tr{
    border-bottom: 1px solid #cccccc;
}

.sharing .tablerowsharing td {
    border: 0px solid #cccccc;
    background-color: #fff !important;
    background: #fff !important;
}
  
.sharing .ant-radio-button-wrapper-checked{
    z-index: 1;
    color: #fff;
    background-color: #ff7f27;
    box-shadow: -1px 0 0 0 #ff7f27;
    border-color: #ff7f27;
}

.sharing .ant-radio-button-wrapper-checked:first-child {
    border-color: #ff7f27;
    box-shadow: none!important;
}

.sharing .ant-tag{
    margin-right: 3px;
}

.sharing .ant-tag-checkable-checked{
    background-color: #ff7f27;
}

.sharing .ant-tag-checkable {
    border: 1px solid #ff7f27;
}



.sharing1 .ant-radio-button-wrapper-checked{
    z-index: 1;
    color: #fff;
    background-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
    border-color: #ff234c;
}

.sharing1 .ant-radio-button-wrapper-checked:first-child {
    border-color: #ff234c;
    box-shadow: none!important;
}

@media screen and (min-width: 1866px) {
    .headCol{
        width:104px;
        height:26px;
    }

    .dataCol{
        width:26px;
        height:26px;
    }

    .dateCol{
        width:104px;
        height:26px;
    }

    .dateCol1{
        width:104px;
        height:26px;
    }
}

@media only screen and (min-width : 1703px) and (max-width : 1865px) {
    .headCol{
        width:92px;
        height:26px;
    }


    .dataCol{
        width:23px;
        height:26px;
    }

    .dateCol{
        width:92px;
        height:26px;
    }

    .dateCol1{
        width:92px;
        height:26px;
    }
}

@media only screen and (min-width : 1381px) and (max-width : 1702px) {
    .headCol{
        width:72px;
        height:23px;
        font-size: 13px;
    }


    .dataCol{
        width:18px;
        height:23px;
        font-size: 13px;
    }

    .dateCol{
        width:87px;
        height:23px;
        font-size: 13px;
    }

    .dateCol1{
        width:87px;
        height:23px;
        font-size: 13px;
    }
}

@media only screen and (min-width : 1216px) and (max-width : 1381px) {
    .headCol{
        width:64px;
        height:42px;
        font-size: 12px;
    }


    .dataCol{
        width:16px;
        height:23px;
        font-size: 12px;
    }

    .dateCol{
        width:70px;
        height:23px;
        font-size: 12px;
    }

    .dateCol1{
        width:70px;
        height:42px;
        font-size: 12px;
    }
}

@media only screen and (min-width : 920px) and (max-width : 1215px) {
    .headCol{
        width:40px;
        height:42px;
        font-size: 11px;
    }


    .dataCol{
        width:10px;
        height:42px;
        font-size: 11px;
    }

    .dateCol{
        width:40px;
        height:42px;
        font-size: 11px;
    }

    .dateCol1{
        width:40px;
        height:42px;
        font-size: 11px;
    }
}
