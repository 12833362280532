.PageTabs{
    padding: 10;
}
.ant-radio-button-wrapper-checked:active {
    color: #fff;
    border-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
}
.ant-radio-button-wrapper:active{
    background-color: #ff234c;

}
.ant-radio-button-wrapper-checked:hover{
    color: #fff;
}
.TitleOfTheBox{
    text-align:left; 
}
.ant-radio-button-wrapper-checked {
    z-index: 1;
    color: #fff;
    background-color: #ff234c;
    border-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
    border-color: #ff234c;
}
.ant-radio-button-wrapper ant-radio-button-wrapper-checked{
    color: #fff;
}
.ant-radio-button-wrapper-checked:first-child {
    border-color: #ff234c;
    box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:hover {
    border-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
    color: #fff;

}

.ant-switch-checked{
    background-color: #ff234c;
}

.MainTable{
    width: 100%;
}
.box{
    width: 100%;
    background-color: white;
    border-radius: 6px;
    height: 100px;
    text-align: left;
}

.Removiebtn{
    background-color: #455773;
    color: #fff;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px;
    margin-left: 10%;
    cursor: pointer;
    /* margin-right: 17px; */
}

.boxSetting{
    width: 96%;
      background-color: white;
      border-radius: 6px;
      text-align: left;
      padding: 15px;
      margin-left: 2%;
      height: 310px;
      margin-top: 5px;  
      /* margin-right: 5%; */
}

.TitleOfTheBox{
    color: #405069;
    font-size: 18px;
    font-weight: 500;
    line-height: 38.67px;
    letter-spacing: 0.04px;
    
}
.SmallTextRoom{
    color: #405069;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.04px;
    margin-bottom: 4px;
}

.Editbtn{
    background-color: #455773;
    color: #fff;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    margin-left: 10%;
    cursor: pointer;
    /* margin-right: 17px; */
}
.Addbtn{
    background-color: #455773;
    color: #fff;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    cursor: pointer;
    /* margin-right: 17px; */
}
.yellowTextRoom{
  color: #ff7f27;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02px;
  text-align: center;

}
.ant-select-lg{
    font-size: 14px;
}

.zoomsetting .ant-select-lg .ant-select-selection--single{
    height: 38px !important;
}

.bookingselect .ant-select-lg .ant-select-selection--single{
    height: 40px !important;
}

.zoomsetting .ant-select-lg .ant-select-selection__rendered {
    line-height: 38px !important;
}

.bookingselect .ant-select-lg .ant-select-selection__rendered {
    line-height: 40px !important;
}

/* .ant-select-lg .ant-select-selection--single {
    height: 30px;
}

.ant-select-lg .ant-select-selection__rendered {
    line-height: 28px;
} */
.ant-select-disabled .ant-select-selection{
    color:rgba(0, 0, 0, 0.65);
}

.ant-list-pagination {
    margin-top: 0px;
    text-align: right;
}