@media screen and (min-width: 1866px) {
    .headCol{
        width:104px;
        height:26px;
    }

    .dataCol{
        width:26px;
        height:26px;
    }

    .dateCol{
        width:104px;
        height:26px;
    }

    .dateCol1{
        width:104px;
        height:26px;
    }
}

@media only screen and (min-width : 1703px) and (max-width : 1865px) {
    .headCol{
        width:92px;
        height:26px;
    }


    .dataCol{
        width:23px;
        height:26px;
    }

    .dateCol{
        width:92px;
        height:26px;
    }

    .dateCol1{
        width:92px;
        height:26px;
    }
}

@media only screen and (min-width : 1381px) and (max-width : 1702px) {
    .headCol{
        width:72px;
        height:23px;
        font-size: 13px;
    }


    .dataCol{
        width:18px;
        height:23px;
        font-size: 13px;
    }

    .dateCol{
        width:87px;
        height:23px;
        font-size: 13px;
    }

    .dateCol1{
        width:87px;
        height:23px;
        font-size: 13px;
    }
}

@media only screen and (min-width : 1216px) and (max-width : 1381px) {
    .headCol{
        width:64px;
        height:42px;
        font-size: 12px;
    }


    .dataCol{
        width:16px;
        height:23px;
        font-size: 12px;
    }

    .dateCol{
        width:70px;
        height:23px;
        font-size: 12px;
    }

    .dateCol1{
        width:70px;
        height:42px;
        font-size: 12px;
    }
}

@media only screen and (min-width : 920px) and (max-width : 1215px) {
    .headCol{
        width:40px;
        height:42px;
        font-size: 11px;
    }


    .dataCol{
        width:10px;
        height:42px;
        font-size: 11px;
    }

    .dateCol{
        width:40px;
        height:42px;
        font-size: 11px;
    }

    .dateCol1{
        width:40px;
        height:42px;
        font-size: 11px;
    }
}