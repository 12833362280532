.Tab2Div1 {
    height: calc(100vh - 140px);
    overflow-y:auto;
}


.ant-tooltip-inner {
    width: 300px;
}

.summarycol1{
    border:1px solid #aaaaaa;
    border-right:0px;
    border-top:0px;
    height:20px;
    text-align:center;
}

.summarycol{
    border:1px solid #aaaaaa;
    border-right:0px;
    height:20px;
    text-align:center;
}

.sharing .ant-select-selection__rendered{
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    line-height: 24px;
}

.sharing1 .ant-select-selection__rendered{
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    line-height: 30px;
}

.sharing .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td{
    padding:4px 4px;
}

.sharing .ant-btn-danger {
    color: #ffffff;
    background-color: #f5222d;
    border-color: #f5222d;
}

.sharing .ant-table-thead, .sharing .ant-table-thead>tr>th{
    background: #002140;
    color:white;
}
  
.sharing .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover{
    background: #0380f57c;
}

.sharing .ant-table-tbody{
    background: #fff;
}

.sharing .ant-table-tbody > tr > td {
    background-color: #fff;
    color: #405069;
    font-weight: 400;
    font-size: 13px;
}

.tablerowselcted > td{
    background-color: bisque !important;
    background: bisque !important;
}


.sharing .ant-table-tbody > tr{
    border-bottom: 1px solid #cccccc;
}

.sharing .tablerowsharing td {
    border: 0px solid #cccccc;
    background-color: #fff !important;
    background: #fff !important;
}
  
.sharing .ant-radio-button-wrapper-checked{
    z-index: 1;
    color: #fff;
    background-color: #ff7f27;
    box-shadow: -1px 0 0 0 #ff7f27;
    border-color: #ff7f27;
}

.sharing .ant-radio-button-wrapper-checked:first-child {
    border-color: #ff7f27;
    box-shadow: none!important;
}

.sharing .ant-tag{
    margin-right: 3px;
}

.sharing .ant-tag-checkable-checked{
    background-color: #ff7f27;
}

.sharing .ant-tag-checkable {
    border: 1px solid #ff7f27;
}



.sharing1 .ant-radio-button-wrapper-checked{
    z-index: 1;
    color: #fff;
    background-color: #ff234c;
    box-shadow: -1px 0 0 0 #ff234c;
    border-color: #ff234c;
}

.sharing1 .ant-radio-button-wrapper-checked:first-child {
    border-color: #ff234c;
    box-shadow: none!important;
}
