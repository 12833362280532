@media screen and (min-width: 1866px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 210px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 180px;
    }

    .dayboxs1 {
        width: 210px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 45px;
        min-width: 210px !important;
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 210px !important;
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 90px !important;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 45px;
        min-width: 22.5px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 22.5px !important;
        cursor: pointer;
        background-color: #fff;
    }
}

@media only screen and (min-width : 1710px) and (max-width : 1875px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 180px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 165px;
    }

    .dayboxs1 {
        width: 180px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 180px !important;
        text-align: center;
        font-size: 10px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 180px !important;
        text-align: center;
        font-size: 10px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 82.5px !important;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 20.625px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 20.625px !important;
        cursor: pointer;
        background-color: #fff;
    }
}


@media only screen and (min-width : 1500px) and (max-width : 1709px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 11px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 11px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        align-items: center;
        font-size: 11px;
        font-weight: 500;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 170px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 140px;
    }

    .dayboxs1 {
        width: 170px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 170px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 170px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 70px !important;
        cursor: pointer;
        text-align: center;
        font-size: 11px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 17.5px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 17.5px !important;
        cursor: pointer;
        background-color: #fff;
    }
}


@media only screen and (min-width : 1260px) and (max-width : 1499px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        align-items: center;
        font-size: 10px;
        font-weight: 450;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 160px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 115px;
    }

    .dayboxs1 {
        width: 160px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 57.5px !important;
        cursor: pointer;
        text-align: center;
        font-size: 10px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 14.375px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 14.375px !important;
        cursor: pointer;
        background-color: #fff;
    }
}

@media only screen and (min-width : 920px) and (max-width : 1259px) {
    .TimeRangeBox {
        background-color: #e4e4e4;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .TimeRangeBox1 {
        background-color: #fff;
        padding: 9px;
        border-right: 0.5px solid white;
        flex: 1;
        justify-Content: center;
        align-Items: center;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        height: 39px;
    }

    .roomnamecolb {
        height: 30px !important;
    }

    .prevnextbtn {
        width: 50%;
        border: 1px solid grey;
        height: 100%;
        display: grid;
        align-items: center;
        font-size: 10px;
        font-weight: 450;
    }

    .firstbox {
        margin-top: 5px;
        height: 39px;
        width: 160px;
    }

    .dayboxs {
        margin-top: 5px;
        width: 110px;
    }

    .dayboxs1 {
        width: 160px;
    }

    .tablerow1b td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow1bz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 160px !important;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerow2tdb {
        border: 1px solid #cccccc;
        height: 30px;
        min-width: 55px !important;
        cursor: pointer;
        text-align: center;
        font-size: 9px;
        font-weight: 450;
        background-color: #fff;
    }

    .tablerowb td {
        border: 1px solid #cccccc;
        height: 36px;
        min-width: 13.75px !important;
        cursor: pointer;
        background-color: #fff;
    }

    .tablerowbz td {
        border: 1px solid #cccccc;
        height: 60px;
        min-width: 13.75px !important;
        cursor: pointer;
        background-color: #fff;
    }
}